import React, { useEffect, useState } from 'react';

const NewsLetterForm = ({
  portalId,
  formId,
  cssClass,
  tyClassName,
}: {
  portalId: string;
  formId: string;
  cssClass?: string;
  tyClassName?: string;
}) => {
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const createForm = () => {
    window.hbspt.forms.create({
      region: 'na1',
      portalId: portalId,
      formId: formId,
      target: `#newsLetterFormId-${formId}`,
      cssClass: cssClass ? cssClass : '',
      submitText: 'Subscribe',
    });
  };

  const onHubSubmit = (event) => {
    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmitted' &&
      event.data.id === formId
    ) {
      setSubmitSuccess(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', onHubSubmit);
    if (!window.hbspt) {
      const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
        if (window.hbspt) {
          createForm();
        }
      });
    } else {
      createForm();
    }
    return () => {
      window.removeEventListener('message', onHubSubmit);
    };
  }, []);

  return (
    <div className="mt-3">
      {submitSuccess ? (
        <p className={tyClassName}>
          Thank you for subscribing to our newsletter!
        </p>
      ) : (
        <div
          id={`newsLetterFormId-${formId}`}
          className="base-newsletter-form"
        />
      )}
    </div>
  );
};

export default NewsLetterForm;
