import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/common/v5/layout';
import SEO from '@/components/seo';
import { cn } from '@/utils/tailwindUtils';
import {
  Hero,
  Container,
  Metrics,
  EvaluateObserveProtect,
  Integrations,
} from '@/components/homepage/v6';

const Index = ({ data }) => {
  const { hero, seo, integration } = data.sanityHomepageV2;

  return (
    <Layout>
      <div className="bg-white">
        <Hero hero={hero} />
        <Metrics />
        <EvaluateObserveProtect />
        <Integrations integration={integration} />
      </div>
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query HomePageQuery {
    sanityHomepageV2 {
      seo {
        metaTitle
        metaDescription
        image {
          asset {
            gatsbyImageData(width: 1280)
            url
            localFile(width: 1280) {
              publicURL
              childImageSharp {
                fixed(width: 1280) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }
      hero {
        rightToLeftLogos {
          asset {
            url
          }
        }
        leftToRightLogos {
          asset {
            url
          }
        }
      }
      integration {
        rightToLeftLogos {
          asset {
            url
          }
        }
        leftToRightLogos {
          asset {
            url
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const {
    seo: { metaTitle, metaDescription, image },
  } = data.sanityHomepageV2;

  return (
    <SEO
      title={metaTitle}
      description={metaDescription}
      image={'/home-v2/homepage-og-image.png'}
      imageWidth={
        image ? image.asset.localFile.childImageSharp.fixed.width : null
      }
      imageHeight={
        image ? image.asset.localFile.childImageSharp.fixed.height : null
      }
    />
  );
};
