import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import NewsletterForm from '@/components/common/v5/newsletter-form';
import AppLink from '@/app-link';
import { Container } from '@/components/homepage/v6';

const Footer = () => {
  const query = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        headerLinks {
          ... on SanityDropDownItem {
            _key
            _type
            dropDownItems {
              ... on SanityExternalLink {
                _key
                _type
                url
                label
              }
              ... on SanityInternalLink {
                _key
                _type
                label
              }
            }
            label
          }
        }

        copyrightMessage
        socialLinks {
          name
          url
        }
        logoDark {
          asset {
            gatsbyImageData
          }
        }

        faqItems {
          question

          _rawAnswer(resolveReferences: { maxDepth: 10 })
        }
        footerLinksFive {
          label
          columnItems {
            ... on SanityExternalLink {
              _type
              url
              label
            }
            ... on SanityDownloadLink {
              _type
              url
              label
            }
            ... on SanityInternalLink {
              _type
              label
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);

  const { copyrightMessage, footerLinksFive, logoDark } =
    query.sanitySiteSettings;

  return (
    <footer
      className="border-t border-solid border-t-[#E9E8ED] bg-white"
      id="sign-up"
    >
      <Container className="relative z-10 overflow-hidden px-4 pb-10 pt-12 md:pt-24 lg:px-4">
        <div className="flex flex-wrap items-start justify-start gap-8 text-white/60 md:justify-between">
          <div className="flex w-auto flex-col items-start justify-start">
            <Link to="/" aria-label="Galileo Home">
              <img
                src={logoDark.asset.gatsbyImageData.images.fallback.src}
                height={40}
                width={123}
              />
            </Link>

            <div className="my-6 w-full">
              <p className="text-left text-base text-[#3b323299] lg:mt-0">
                Subscribe to our newsletter
              </p>

              <div className="flex flex-row space-x-2">
                <NewsletterForm
                  tyClassName="text-left text-base text-[#3b323299]"
                  cssClass="inline-newsletter-form"
                  portalId="23114811"
                  formId="646b31c4-03b9-48bc-bcc6-048771d5caf9"
                />
              </div>
            </div>
          </div>
          <div className="w-full md:w-auto">
            <div className="flex flex-row flex-wrap gap-10 max-md:gap-8">
              {footerLinksFive.map(({ label, columnItems }, index) => (
                <div
                  className={`md:w-auto md:min-w-[9rem] ${
                    index == 0 ? 'w-full' : 'w-[40%]'
                  }`}
                  key={label + index}
                >
                  <p className="mb-4 text-xs font-light uppercase text-[#3B3232] md:block">
                    {label}
                  </p>
                  <ul>
                    {columnItems.map((columnLink, idx) => (
                      <li className="mx-0 my-2" key={idx}>
                        <AppLink link={columnLink} />
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse justify-between gap-6 pt-10 text-start text-sm text-grey-400 sm:flex-row sm:items-end lg:pt-11">
          <div>
            <p>
              Contact us at{' '}
              <a
                href="mailto:info@rungalileo.io"
                className="bg-gradient-to-br from-reddish-magenta via-pb to-purply-blue bg-clip-text text-transparent antialiased"
              >
                info@rungalileo.io
              </a>
            </p>
            <p>{copyrightMessage}</p>
          </div>

          <img
            className="h-[82px] w-[82px]"
            src="/footer-logo-right-corner.svg"
            alt="AICPA SOC Logo"
          />
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
